import { fromUint8Array } from 'js-base64'
import { gzip } from 'pako'

import { Config } from '@constants/api'

/**
 * TODO: catch errors and handle them
 */
export abstract class ApiClass {
    fetcher: any

    public setFetcher(fetcher: any) {
        this.fetcher = fetcher
    }

    public async fetch(url: string, options: Partial<RequestInit> = {}, avoidCookieCheck = false) {
        let counter = 0

        while (!this.fetcher && counter < 10) {
            counter++
            await new Promise(resolve => {
                return setTimeout(resolve, 10)
            })
        }

        if (this.fetcher) {
            return this.fetcher(url, options, avoidCookieCheck)
        }

        return fetch(url, options)
    }

    /**
     * Performs a GET request to the given URL.
     *
     * @param url
     * @param fallbackToPOST
     * @param requestBody
     * @param options
     * @param avoidCookieCheck -- see AuthStore.check() for more info
     */
    async get(url: string, fallbackToPOST = false, requestBody = {}, options: Partial<RequestInit> = {}, avoidCookieCheck = false) {
        try {
            const body = {
                ...requestBody,
            }
            const jsonStringBody = JSON.stringify(body)

            // zip and base64 encode the jsonString (30%-40% smaller for long queries)
            const zippedBody = fromUint8Array(gzip(jsonStringBody), true)
            const requestUrl = `${url}/${zippedBody}`

            if (requestUrl.length > Config.urlLengthLimit && fallbackToPOST) {
                return await this.post(url, requestBody)
            }

            const getUrl = Object.keys(body).length > 0 ? requestUrl : url
            const response = await this.fetch(
                getUrl,
                {
                    method: 'GET',
                    credentials: !options.credentials ? 'same-origin' : options.credentials,
                    ...options,
                },
                avoidCookieCheck,
            )

            // Fetcher already parses the response
            return this.fetcher ? response : await response.json()
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Request url: ', url)
            console.error(`Error during GET request: ${error}`)
            throw error
        }
    }

    async post(url: string, requestBody: any = null, options: Partial<RequestInit> = {}) {
        try {
            const response = await this.fetch(url, {
                method: 'POST',
                credentials: !options.credentials ? 'same-origin' : options.credentials,
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                },
                ...options,
            })

            // Fetcher already parses the response
            return this.fetcher ? response : await response.json()
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Request url: ', url)
            console.error(`Error during POST request: ${error}`)
            throw error
        }
    }
}
